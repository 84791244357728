<template>
  <div id="local-regional-dashboard">
    <local-regional-saved-searches-form />
    <base-layout>
      <template v-slot:drawer-left>
        <sidebar v-if="nlLrFiltersAreSet" />
      </template>

      <template v-slot:identity>
        <a
          href="/nl-lr/dashboard"
          class="d-flex align-start"
        >
          <img
            style="cursor: pointer;"
            src="../../../assets/logo-transparant-groot.png"
            alt="Polpo - Politieke monitoring"
            height="42"
          >
        </a>
      </template>

      <template v-slot:searchbar>
        <!-- <search-bar /> -->
        <search-bar
          on-search-action="localRegionalGetAllTimelineItems"
          set-query-action="setQuery"
          search-query="searchParameterQuery"
          :query-length="1000"
          :placeholder="$t('document.searchInDocuments')"
        />
      </template>

      <template v-slot:main-content>
        <v-container
          fluid

        >
          <v-row v-if="localRegionalTimelineItemsCountTotal > 0 && statisticsTrend">
            <v-col cols="12">
              <v-expansion-panels
                :focusable="false"
                v-if="trendAnalyticsExpanded"
                v-model="singlepanel"
                multiple
              >
                <trend-analytics-chart
                  statisticsTrendType="localRegionalStatisticsTrend"
                  statisticsTimelineType="localRegionalGetAllTimelineItems"
                />
              </v-expansion-panels>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col
              v-if="localRegionalTimelineItemsCountTotal >= 0"
              cols="12"
              lg="6"
              xl="6">
              <list-timeline
                nextItemUrlString="localRegionalNextItemUrl"
                setNextItemUrlString="localRegionalSetNextItemUrl"
                timeline-items-string="localRegionalTimelineItems"
                timeline-items-count-total-string="localRegionalTimelineItemsCountTotal"
                timeline-items-has-more-string="localRegionalTimelineItemsHasMore"
                get-all-timeline-items-next-string="localRegionalGetAllTimelineItemsNext"
                get-all-timeline-items-string="localRegionalGetAllTimelineItems"
                maxDateRangeTimelineItemsTotalString="localRegionalMaxDateRangeTimelineItemsTotal"
                maxDateRangeTimelineItemsHasMoreString="localRegionalMaxDateRangeTimelineItemsHasMore"
              />
            </v-col>
            <v-col
              v-if="localRegionalTimelineItemsCountTotal > 0 && statisticsTrend"
              cols="12"
              lg="6"
              xl="6"
              class="d-none d-lg-block"
            >
              <v-expansion-panels
                :focusable="false"
                v-model="panel"
                multiple
              >
                <trend-analytics-chart
                  v-if="!trendAnalyticsExpanded"
                  statisticsTrendType="localRegionalStatisticsTrend"
                  statisticsTimelineType="localRegionalGetAllTimelineItems"
                />
                <list-stakeholders
                  title="Activiteit"
                  analyticsConfigKey="nlLrAnalyticsTypes"
                  analyticsGetter="localRegionalStatisticsStakeholders"
                  analyticsConfigDefaultKey="nlLrDefaultAnalyticsCode"
                  analyticsLocalStorageKey="nlLrAnalyticsCode"
                  analyticsFunction="localRegionalGetStatistics"
                />
                <list-trending-topics
                  trendingTopicsType="localRegionalStatisticsTrendingTopics"
                />
              </v-expansion-panels>
            </v-col>
          </v-row>
        </v-container>

      </template>

      <template v-slot:drawer-right>
        <saved-searches
          class="mt-n4"
          saved-searches-category-string="localRegional"
          current-search-string="localRegionalCurrentSearch"
          saved-searches-string="localRegionalAllSavedSearches"
          recent-searches-string="localRegionalAllRecentSearches"
          max-number-of-searches-string="localRegionalMaxNumberOfSearches"
          all-search-parameters-string="allSearchParameters"
          set-current-search-string="localRegionalSetCurrentSearch"
          remove-search-string="localRegionalRemoveSearch"
          update-search-string="localRegionalUpdateSearch"
          set-all-search-parameters-string="localRegionalSetAllSearchParameters"
          set-dialog-form-saved-searches-visible-string="setLocalRegionalDialogFormSavedSearchesVisible"
          saved-searches-item-detail-recent-string="localRegionalSavedSearchesItemDetailRecent"
          saved-searches-item-detail-saved-string="localRegionalSavedSearchesItemDetailSaved"
        />
      </template>

    </base-layout>
  </div>
</template>

<script>
// Vuex
import { mapGetters } from 'vuex';

// @ is an alias to /src
import BaseLayout from '@/components/BaseLayout.vue';
import TrendAnalyticsChart from '@/components/TrendAnalyticsChart.vue';
import SearchBar from '@/components/SearchBar.vue';
import ListTimeline from '@/components/ListTimeline.vue';
import ListStakeholders from '@/components/ListStakeholders.vue';

import ListTrendingTopics from '@/components/ListTrendingTopics.vue';
import SavedSearches from '@/components/SavedSearches.vue';
import FeatureTeaserListManager from '@/components/FeatureTeaserListManager.vue';
import AgendaTeaserDialog from '@/modules/agenda/components/AgendaTeaserDialog.vue';
import { showDialog } from '@/helpers/dialogHelper';
import LocalRegionalSavedSearchesForm from '@/modules/local-regional/components/LocalRegionalSavedSearchesForm.vue';
import Sidebar from '../components/LocalRegionalSidebar.vue';

export default {
  name: 'local-regional-dashboard',

  components: {
    LocalRegionalSavedSearchesForm,
    BaseLayout,
    TrendAnalyticsChart,
    Sidebar,
    SearchBar,
    ListTimeline,
    ListStakeholders,
    ListTrendingTopics,
    SavedSearches,
  },

  computed: {
    ...mapGetters({
      authHasModuleAccess: 'authHasModuleAccess',
      localRegionalTimelineItems: 'localRegionalTimelineItems',
      localRegionalTimelineItemsCountTotal: 'localRegionalTimelineItemsCountTotal',
      localRegionalAllSearchParameters: 'localRegionalAllSearchParameters',
      searchParameters: 'allSearchParameters',
      statisticsTrend: 'localRegionalStatisticsTrend',
      trendAnalyticsExpanded: 'trendAnalyticsExpanded',
      isIE11: 'isIE11',
    }),
  },

  data: () => ({
    nlLrFiltersAreSet: false,
    toggleTrendAnalyticsExpanded: false,
    singlepanel: [0],
    panel: [0, 1, 2],
    prevRoute: null,
  }),

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from;
    });
  },

  methods: {
    showTeaserDialog() {
      showDialog(FeatureTeaserListManager);
    },
    showTeaserAgendaModule() {
      showDialog(AgendaTeaserDialog);
    },

    async parseQueryParams(queryParams) {
      const {
        query,
        groupPaths,
      } = queryParams;
      const dateRangeISO = {
        startDate: queryParams.startDate,
        endDate: queryParams.endDate,
      };

      // Check if there are any query parameters set
      if (query) {
        this.$store.dispatch('setQuery', query);
      }

      this.$store.dispatch('setGroupPaths', (groupPaths && groupPaths.split(',').length > 0) ? groupPaths.split(',') : []);

      if (this.$route.query.startDate && this.$route.query.endDate) {
        this.$store.dispatch('setDateRange', dateRangeISO);
      }

      if (query) {
        this.$store.dispatch('localRegionalGetAllTimelineItems');
      }
    },
  },

  watch: {
    trendAnalyticsExpanded(bool) {
      // Lets open the panels after the user altered the settings
      if (!bool) {
        this.panel = [0, 1, 2];
      } else {
        this.singlepanel = [0];
      }
    },
  },

  async mounted() {
    this.$gtag.pageview({
      page_path: '/lr/dashboard',
    });

    await this.$store.dispatch('localRegionalClearTimelineItems');
    await this.$store.dispatch('localRegionalSetCurrentSearch', {});

    await this.$store.dispatch('getFilterTypesForUser');
    await this.$store.dispatch('getNlLrFilterTypesForUser').then(() => {
      this.nlLrFiltersAreSet = true;
    });
    await this.$store.dispatch('clearMessages');
    await this.parseQueryParams(this.$route.query);

    await this.$store.dispatch('localRegionalGetAllSavedSearches');
    await this.$store.dispatch('getPreferences');
    this.$store.dispatch('setIsIE11');
    if (this.isIE11) {
      this.panel = [1, 2];
    }
  },
};
</script>

<style lang="scss">
  ul.plain-li {
    & {
      list-style: none;
    }
    & li::before {
      content: "\2022";
      color: teal;
      font-weight: bold;
      display: inline-block;
      width: 1em;
      margin-left: -1em;
    }
  }
</style>
