<template>
  <div>
    <v-container class="mt-n6">
      <v-row>
        <v-col>
          <v-subheader
            class="mb-n2 overline"
          >
            Periode
          </v-subheader>
          <v-card
            class="pa-2"
          >
            <filter-date-range
              getTimelineItemsString="localRegionalGetAllTimelineItems"
            />
          </v-card>
        </v-col>
      </v-row>
      <!-- <v-row>
        <v-col>
          <v-subheader
            class="mt-n4 mb-n2 overline"
          >
            Minimale match
          </v-subheader>
            <v-card
              class="pa-2"
            >
             <filter-minimal-match-percentage />
          </v-card>

        </v-col>
      </v-row> -->
      <v-row>
        <v-col>
          <v-subheader
            class="mt-n4 mb-n2 overline"
          >
            Bronnen
          </v-subheader>
          <v-card
            class="py-2"
          >
            <local-regional-tree-document-types />
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <div
            class="my-14"
          >
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import FilterDateRange from '@/components/FilterDateRange.vue';
import LocalRegionalTreeDocumentTypes from '@/components/LocalRegionalTreeDocumentTypes.vue';

export default {

  components: {
    FilterDateRange,
    LocalRegionalTreeDocumentTypes,
  },

  computed: {
  },

};
</script>
